import type { FC } from 'react'
import styles from './Modal.module.scss'
import { ModalProps } from './type'

const Modal: FC<ModalProps> = ({ children,onClose }) => (
  <div onClick={onClose}  className={styles.modal}>
    <div className={styles.modal__block}>{children}</div>
  </div>
)
{
}
export default Modal
