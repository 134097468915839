import { type FC, useState, useCallback } from 'react'

import { HeartIcon, HeartIcon1 } from 'assets'
import { Button, RatingStars } from 'components'

import type { TCardProps } from './types'
import styles from './Card.module.scss'
import { useResponsive } from 'hooks'
import { useAppDispatch } from 'hooks/useTypedSelector'
import { addWatchList, removeWatchList } from 'store/features/Watchlist/watchListSlice'

const Card: FC<TCardProps> = ({
  flexGrow,
  id,
  image,
  title,
  rate,
  reviwers,
  price,
  promotion,
  oldPrice,
  isCheck = false,
  saved,
}) => {
  const { isTablet } = useResponsive()

  let x = null
  let percent = 0

  if (price && oldPrice) {
    x = (parseInt(price.replace(/\s/g, '')) - parseInt(oldPrice.replace(/\s/g, ''))) * 100

    percent = Math.floor(x / parseInt(price.replace(/\s/g, '')))
  }

  const dispatch = useAppDispatch()
  const [isLiked, setLiked] = useState(saved)

  const onLikeClickCallback = useCallback(
    (event: any) => {
      event.preventDefault()
      const updatedIsLiked = !isLiked
      setLiked(updatedIsLiked)

      if (updatedIsLiked === true) {
        dispatch(addWatchList(id))
      } else {
        dispatch(removeWatchList(id))
      }
    },
    [isLiked]
  )

  const renderHeartIcon = isLiked ? (
    <HeartIcon role='button' onClick={onLikeClickCallback} className={styles.wrapper__like} />
  ) : (
    <HeartIcon1 role='button' onClick={onLikeClickCallback} className={styles.wrapper__like} />
  )

  const renderCheckedIcon = <button></button>

  return (
      <a style={{ flexGrow:  1 }} className={styles.wrapper} href={`/product/${id}`}>
        <img className={styles.wrapper__image} src={image} alt={title} width='100%' height='auto' />

        {!isCheck ? !isTablet && renderHeartIcon : renderCheckedIcon}

        <div className={styles.wrapper__description}>
          {/* {promotion ? (
            <div className={styles.wrapper__prom}>-{percent} %</div>
          ) : (
            <div className={styles.wrapper__new}>Новинки</div>
          )} */}

          <p className={styles.wrapper__title}>{title}</p>

          <div className={styles.wrapper__rating}>
            <RatingStars rate={rate} />

            <p className={styles.wrapper__reviewers}>{reviwers} відгуків</p>
          </div>

          {!promotion ? (
            <p className={styles.wrapper__price}>{price}</p>
          ) : (
            <p className={styles.wrapper__price__promotion}>
              {oldPrice} <span className={styles.wrapper__price__old}>{price}</span>
            </p>
          )}

          {isTablet && (
            <div className={styles.wrapper__buy}>
              <Button className={styles.wrapper__buy__button}>Купити</Button>

              {renderHeartIcon}
            </div>
          )}
        </div>
      </a>
  )
}

export default Card
