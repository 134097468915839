import type { FC } from 'react'

import { Title } from 'components'

import styles from './FooterImages.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const FooterImages: FC = () => {
  const { settingSlice } = useAppSelector(state => state)

  const instagramImages: any[] = settingSlice.data?.data?.instagram_posts

  const renderImages = instagramImages?.map((element: any, index) => (
    <a href={element.link} target='_blank' key={index}>
      {!element.is_video ? (
        <img style={{ maxWidth: '100%', minWidth: '240px' }} width='240px' height='240px' src={element?.src} />
      ) : (
        <video
          controls
          src={element.src}
          style={{ minWidth: '240px', maxHeight: '240px', maxWidth: '100%', objectFit: 'cover' }}
        />
      )}
    </a>
  ))

  return (
    instagramImages?.length > 0 ? 
    <div className={styles.wrapper}>
      <Title
        title='Слідкуй за нами в Instagram'
        subtitle='Доєднуйся до наших соц мереж та будь з нами на одній хвилі'
      />

      <div className={styles.wrapper__images}>{renderImages}</div>
    </div> : ( null) 
  )
}

export default FooterImages
