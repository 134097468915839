import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TSlice } from '../type'

const initialState: TSlice = {
  data: [],
  loading: false,
  error: null,
  countries: [],
  cities: [],
  warehaouses: [],
  streets: [],
  payments: [],
}

export const createOrder = createAsyncThunk('order', async (orderData: any) => {
  try {
    const response = await api.post(`/shop/v1/order`, orderData)

    return response
  } catch (err) {
    throw Error('Failed to make new order')
  }
})

export const fetchCountries = createAsyncThunk('countries', async () => {
  try {
    const response = await api.get('/v2/countries?filter_exclude_default=1', {})

    return response.data
  } catch (err) {
    throw Error('Failed to fetch countries')
  }
})

export const fetchCities = createAsyncThunk('cities', async (filterName: string) => {
  try {
    const response = await api.get('/v1/localisation/cities', {
      params: {
        filter_name: filterName,
      },
    })

    return response.data
  } catch (err) {
    throw Error('Failed to fetch cities')
  }
})

export const fetchWarehouses = createAsyncThunk(
  'warehouses',
  async ({ cityRef, filterName }: { cityRef: any; filterName: any }) => {
    try {
      const response = await api.get(`/v1/localisation/warehouses?filter_name=${filterName}&CityRef=${cityRef}`)

      return response.data
    } catch (err) {
      throw Error('Failed to fetch warehouse')
    }
  }
)

export const fetchStreets = createAsyncThunk(
  'streets',
  async ({ cityRef, filterName }: { cityRef: any; filterName: any }) => {
    try {
      const response = await api.get(`/v1/localisation/streets`, {
        params: {
          city_ref: cityRef,
          filter_name: filterName,
        },
      })

      return response.data
    } catch (err) {
      throw Error('Failed to fetch warehouse')
    }
  }
)

export const fetchPaymets = createAsyncThunk('payments', async (shipingCode: string) => {
  try {
    const response = await api.get(`/v2/orders/payment-methods?shipping_code=novaposhta.${shipingCode}`)

    return response
  } catch (err) {
    throw Error('Failed to fetch paymentys')
  }
})

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createOrder.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload
      })

      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload
      })
      .addCase(fetchWarehouses.fulfilled, (state, action) => {
        state.warehaouses = action.payload
      })
      .addCase(fetchStreets.fulfilled, (state, action) => {
        state.streets = action.payload
      })
      .addCase(fetchPaymets.fulfilled, (state, action) => {
        state.payments = action.payload
      })
  },
})

export { orderSlice }
