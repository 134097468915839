import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TSetting, TSettingPayload } from './type'

const initialState: TSettingPayload = {
  data: {
    id: 0,
    name: '',
    link: '',
    head: null,
    body: null,
    phone: {},
    instagram_url: null,
    facebook_url: null,
    description: null,
    banners: [],
    category: {},
    instagram_posts: [],

  },
  loading: false,
  error: null,
}

export const fetchSettingData = createAsyncThunk<TSetting, void>('setting/fetchSettingData', async () => {
  try {
    return await api.get(`/v1/store/${process.env.REACT_APP_STORE_ID}`)
  } catch (err) {
    throw Error('Failed to get setting data')
  }
})

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSettingData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSettingData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchSettingData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch setting'
      })
  },
})

export { settingSlice }
