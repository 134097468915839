import { useEffect, FC, useState } from 'react'
import { RoutesWrapper } from 'router'
import { Footer, Header, MobileNavBar } from 'components'
import styles from './PageLayout.module.scss'
import { useResponsive } from 'hooks'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchSettingData } from 'store/features/Setting/settingSlice'
import { fetchBasketList } from 'store/features/Basket/basketSlice'
import { fetchWatchList } from 'store/features/Watchlist/watchListSlice'

const PageLayout: FC = () => {
  const { isTablet } = useResponsive()
  const { settingSlice } = useAppSelector(state => state)

  const [scriptAdded, setScriptAdded] = useState(false)

  const head: any = settingSlice?.data?.data


  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!scriptAdded) {
      if (head !== null) {
        head?.forEach((item: any) => {
          const script = document.createElement('script')
          const PropsItem = item?.props?.[0]

          for (const key in PropsItem) {
            if (PropsItem.hasOwnProperty(key)) {
              const value = PropsItem[key]
              script.setAttribute(key, value)
            }
          }

          script.setAttribute('data-live-chat-id', '64f44c52d10a09501205142a')
          script.async = item.async

          script.src = item.url

          script.onerror = function (event) {
            console.error('Error loading script:', event)
          }

          document.head.prepend(script)
        })

        setScriptAdded(true)
      }

      if (head?.fb_pixel_id) {
        const fbScript = document.createElement('script')
        fbScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${head.fb_pixel_id}');
        fbq('track', 'PageView');
      `

        const noscriptImg = document.createElement('noscript')
        noscriptImg.innerHTML = `
        <img height="1" width="1" style="display:none" 
             src="https://www.facebook.com/tr?id=${head.fb_pixel_id}&ev=PageView&noscript=1"/>
      `

        document.head.appendChild(fbScript)
        document.body.appendChild(noscriptImg)
      }

      if (head?.ga_tracking_id) {
        const gmScript = document.createElement('script')

        gmScript.src = `https://www.googletagmanager.com/gtag/js?id=${head.ga_tracking_id}`

        gmScript.async = true

        document.head.appendChild(gmScript)


        const gaScript = document.createElement('script')


        gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() {window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${head.ga_tracking_id}');
      `

        document.head.appendChild(gaScript)
      }
    }
  }, [head, scriptAdded])

  useEffect(() => {
    dispatch(fetchSettingData())
    dispatch(fetchBasketList())
    dispatch(fetchWatchList())
  }, [dispatch])

  return (
    <main className={styles.wrapper}>
      <Header />

      <div className={styles.wrapper__container}>
        <RoutesWrapper />
      </div>

      <Footer />

      {isTablet && <MobileNavBar />}
    </main>
  )
}

export default PageLayout
