import { type FC } from 'react'

import { FacebookIcon, InstagramIcon, LogoIcon, PhoneIcon } from 'assets'

import styles from './Footer.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const Footer: FC = () => {
  const { settingSlice } = useAppSelector(state => state)

  return (
    <footer className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <div className={styles.wrapper__center}>
          <p className={styles.wrapper__center__text}>Про нас</p>

          <p className={styles.wrapper__center__text}>Контакти</p>

          <p className={styles.wrapper__center__text}>Блог</p>

          <p className={styles.wrapper__center__text}>Політика конфіденційності</p>
        </div>

        <div className={styles.wrapper__social}>
          <div className={styles.wrapper__social__icons}>
            {settingSlice.data.data?.instagram_url && (
       
                <a href={settingSlice.data.data.instagram_url} target='__blank'>
                  <InstagramIcon />
                </a>
            )}
            {settingSlice.data.data?.facebook_url && (
              <a href={settingSlice.data.data.facebook_url} target='__blank'>
                <FacebookIcon />
              </a>
            )}
          </div>
          {settingSlice.data?.data?.phone.international && (
            <a href={`tel:${settingSlice.data.data.phone.mobile}`}>
              <div className={styles.wrapper__social__phone}>
                <PhoneIcon fill='white' />

                <p className={styles.wrapper__social__phone__number}>{settingSlice.data.data?.phone.international}</p>
              </div>
            </a>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
